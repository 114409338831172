import React from 'react';

const ForkliftIcon = ({ fillColor }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.25,-0.25)" fill={fillColor} stroke="none">
      <path d="M490 1913 c-88 -29 -90 -37 -90 -391 l0 -300 -105 -4 c-103 -3 -105
-4 -137 -36 l-33 -32 -3 -201 c-2 -111 0 -199 5 -197 98 49 175 60 248 35 107
-37 179 -135 180 -244 l0 -63 186 0 187 0 -9 42 c-29 127 61 280 191 323 76
25 163 17 233 -23 l54 -31 -8 42 c-4 23 -8 45 -9 50 0 4 25 7 55 7 l55 0 0
-190 c0 -177 1 -191 20 -210 20 -20 29 -20 318 -14 281 6 298 7 315 26 23 27
22 63 -3 88 -18 18 -33 20 -158 20 -77 0 -187 3 -245 7 l-107 6 0 556 c0 626
3 601 -70 601 -71 0 -70 4 -70 -407 l0 -363 -65 0 c-59 0 -65 2 -70 23 -3 12
-36 199 -75 416 -74 425 -78 436 -141 458 -34 12 -615 18 -649 6z m659 -500
c34 -208 63 -386 65 -395 4 -17 -11 -18 -199 -18 l-202 0 -6 66 c-5 70 -21
101 -70 138 -21 15 -47 21 -117 24 l-90 4 0 279 0 279 278 0 279 0 62 -377z"/>
<path d="M1125 785 c-85 -33 -144 -120 -145 -213 -1 -201 247 -298 387 -151
37 39 63 105 63 158 0 42 -31 116 -63 149 -55 59 -166 85 -242 57z m142 -147
c30 -28 36 -63 18 -102 -38 -80 -149 -69 -170 17 -22 87 85 148 152 85z"/>
<path d="M238 731 c-87 -28 -140 -93 -146 -180 -7 -95 51 -179 142 -206 58
-17 104 -12 158 18 90 49 125 174 76 267 -42 77 -152 126 -230 101z m91 -126
c31 -16 41 -33 41 -71 0 -59 -56 -96 -110 -74 -56 23 -69 92 -25 135 28 29 54
31 94 10z"/>
      </g>
    </svg>
  );
};

export default ForkliftIcon;
